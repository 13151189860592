<template>
    <div class="container">
      <div class="image-container">
        <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
      </div>
      <div class="edit-container">
        <div v-for="(text, key) in editableTexts" :key="key" class="input-group">
          <label>{{ key }}:</label>
          <input v-model="editableTexts[key]" @input="updateCanvas" :placeholder="key">
        </div>
        <button @click="exportImage">导出图片</button>
      </div>
    </div>
  </template>
  
  <script>
  import coverImage from '@/assets/design2.png'
  
  export default {
    data() {
      return {
        editableTexts: {
          topArc: '07145002-856872-50',
          centerTop: 'D400 污水',
          centerMiddle1: '中国·洛阳',
          centerMiddle2: '牡丹花都',
          centerBottom: '2023',
          bottomArc1: '洛阳新奥水暖厂制造/电话/13937933232',
          bottomArc2: '洛阳市政',
          bottom: '400 KN'
        },
        image: null,
        canvasWidth: 500,
        canvasHeight: 500
      }
    },
    mounted() {
      this.loadImage()
    },
    methods: {
      loadImage() {
        this.image = new Image()
        this.image.onload = () => {
          this.canvasWidth = this.image.width
          this.canvasHeight = this.image.height
          this.$nextTick(this.updateCanvas)
        }
        this.image.src = coverImage
      },
      updateCanvas() {
        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d')
        
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(this.image, 0, 0, canvas.width, canvas.height)
        
        this.drawTexts(ctx)
      },
      drawTexts(ctx) {
        const centerX = this.canvasWidth / 2
        const centerY = this.canvasHeight / 2
        const radius = this.canvasWidth * 0.45
  
        // Draw top arc text
        this.drawArcText(ctx, this.editableTexts.topArc, centerX, centerY, radius, Math.PI, true)
  
        // Draw center texts
        ctx.textAlign = 'center'
        ctx.font = `${this.canvasWidth * 0.05}px Arial`
        ctx.fillText(this.editableTexts.centerTop, centerX, centerY - radius * 0.5)
        ctx.fillText(this.editableTexts.centerMiddle1, centerX, centerY)
        ctx.fillText(this.editableTexts.centerMiddle2, centerX, centerY + this.canvasWidth * 0.06)
        ctx.fillText(this.editableTexts.centerBottom, centerX, centerY + radius * 0.5)
  
        // Draw bottom arc texts
        this.drawArcText(ctx, this.editableTexts.bottomArc1, centerX, centerY, radius, 0, false)
        this.drawArcText(ctx, this.editableTexts.bottomArc2, centerX, centerY, radius * 0.9, 0, false)
  
        // Draw bottom text
        ctx.fillText(this.editableTexts.bottom, centerX, this.canvasHeight - this.canvasWidth * 0.05)
      },
      drawArcText(ctx, text, x, y, radius, startAngle, isTopArc) {
        ctx.save()
        ctx.translate(x, y)
        ctx.rotate(startAngle)
  
        const anglePerChar = 0.05
        const totalAngle = text.length * anglePerChar
        const startOffset = isTopArc ? -totalAngle / 2 : -Math.PI + totalAngle / 2
  
        for (let i = 0; i < text.length; i++) {
          ctx.save()
          ctx.rotate(startOffset + i * anglePerChar)
          ctx.translate(0, -radius)
          ctx.rotate(isTopArc ? Math.PI / 2 : -Math.PI / 2)
          ctx.fillText(text[i], 0, 0)
          ctx.restore()
        }
  
        ctx.restore()
      },
      exportImage() {
        const canvas = this.$refs.canvas
        const image = canvas.toDataURL('image/png')
        const link = document.createElement('a')
        link.href = image
        link.download = 'edited_manhole_cover.png'
        link.click()
      }
    }
  }
  </script>
  
  <style scoped>
.container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.image-container {
  flex: 1;
  margin-right: 20px;
}

.edit-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

canvas {
  max-width: 100%;
  height: auto;
}
</style>