<template>
  <div id="app">
    <nav>
      <ul>
        <li><router-link to="/design1">Design 1</router-link></li>
        <!-- <li><router-link to="/design2">Design 2</router-link></li> -->
        <li><router-link to="/design3">Design 3</router-link></li>
      </ul>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
nav {
  margin-bottom: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}
</style>