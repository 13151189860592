<template>
  <div class="container">
    <div class="image-container">
      <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
    <div class="edit-container">
      <div class="input-group">
        <label>第一行:</label>
        <input v-model="text1" @input="updateCanvas" placeholder="爱护公物" />
      </div>
      <div class="input-group">
        <label>第二行:</label>
        <input v-model="text2" @input="updateCanvas" placeholder="弱电" />
      </div>
      <div class="input-group">
        <label>第三行:</label>
        <input v-model="text3" @input="updateCanvas" placeholder="EN124 D400重" />
      </div>
      <button @click="exportImage">导出图片</button>
    </div>
  </div>
</template>

<script>
import coverImage from "@/assets/design1.png";

export default {
  data() {
    return {
      text1: "爱护公物",
      text2: "弱电",
      text3: "EN124 D400重",
      image: null,
      canvasWidth: 412,
      canvasHeight: 580,
    };
  },
  mounted() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      this.image = new Image();
      this.image.onload = () => {
        this.canvasWidth = this.image.width;
        this.canvasHeight = this.image.height;
        this.$nextTick(this.updateCanvas);
      };
      this.image.src = coverImage;
    },
    updateCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(this.image, 0, 0, canvas.width, canvas.height);

      // 设置通用样式
      ctx.fillStyle = "darkblue";
      ctx.textAlign = "center";

      // "爱护公物"
      ctx.font = `bold ${canvas.width / 15}px SimHei, sans-serif`;
      this.drawCenteredText(ctx, this.text1, canvas.width / 2, canvas.height * 0.245);

      // "弱电"
      ctx.font = `bold ${canvas.width / 12}px SimHei, sans-serif`;
      this.drawCenteredText(ctx, this.text2, canvas.width / 2, canvas.height / 1.99);

      // "EN124 D400重"
      ctx.font = `${canvas.width / 18}px Arial, SimHei, sans-serif`;
      this.drawCenteredText(ctx, this.text3, canvas.width / 2, canvas.height * 0.734);
    },
    drawCenteredText(ctx, text, x, y) {
      ctx.fillText(text, x, y);
    },
    exportImage() {
      const canvas = this.$refs.canvas;
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = "edited_image.png";
      link.click();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.image-container {
  flex: 1;
  margin-right: 20px;
}

.edit-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

canvas {
  max-width: 100%;
  height: auto;
}
</style>
