<template>
    <div class="container">
      <div class="image-container">
        <canvas id="canvas" width="500" height="500"></canvas>
      </div>
      <div class="edit-container">
        <p>演示版本，仅供参考，所以没有放出最后2个字段！</p>
        <div v-for="(text, key) in editableTexts" :key="key" class="input-group">
          <label>{{ key }}:</label>
          <input v-model="editableTexts[key]" @input="updateText(key)" :placeholder="key">
        </div>
        <button @click="exportCanvas">导出图片</button>
      </div>
    </div>
  </template>
  
  <script>
  import { fabric } from 'fabric';
  import coverImage from '@/assets/design2.png';
  
  export default {
    data() {
      return {
        canvas: null,
        editableTexts: {
          topArc: 'GB/T23858-2009D40',
          centerTop: 'D400 污水',
          centerMiddle1: '中国·洛阳',
          centerMiddle2: '牡丹花都',
          centerBottom: '2023',
          bottomArc1: '洛阳新奥水暖厂制造/电话/13937933232',
        //   bottomArc2: '洛阳市政',
        //   bottom: '400 KN'
        },
        textObjects: {},
      };
    },
    mounted() {
      this.initCanvas();
    },
    methods: {
      initCanvas() {
        this.canvas = new fabric.StaticCanvas('canvas', {
          selection: false,
          hoverCursor: 'default',
        });
        
        fabric.Image.fromURL(coverImage, (img) => {
          img.scaleToWidth(this.canvas.width);
          this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));
          this.createAllTexts();
        });
      },
      createAllTexts() {
        this.createTopArcText();
        this.createCenterTop();
        this.createCenterTexts();
        this.createBottomArcTexts();
        // this.createBottomText();
      },
      createTopArcText() {
        const path = new fabric.Path('M 0,200 A 100,100 0 0,0 460,200', {
          fill: '',
          stroke: 'rgba(0,0,0,0)',
        });
  
        this.textObjects.topArc = new fabric.Text(this.editableTexts.topArc, {
          fontSize: 14,
          fill: '#000',
          textAlign: 'center',
          path: path,
          pathStartOffset: 0.5,
          charSpacing: 100,
          fontFamily: 'Arial',
          left: 250,
          top: 138,
          originX: 'center',
          originY: 'center',
          angle: 180,
        });
  
        this.canvas.add(this.textObjects.topArc);
      },
      createCenterTop() {
        this.textObjects.centerTop = new fabric.Text(this.editableTexts.centerTop, {
          left: this.canvas.width / 2,
          top: 102,
          fontSize: 17,
          fill: '#000',
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
        });
        this.canvas.add(this.textObjects.centerTop);
      },
      createCenterTexts() {
        const centerX = this.canvas.width / 2;
  
        ['centerMiddle1', 'centerMiddle2', 'centerBottom'].forEach((key, index) => {
          this.textObjects[key] = new fabric.Text(this.editableTexts[key], {
            left: centerX,
            top: 332 + (index - 1.8) * 23,
            fontSize: 18,
            fill: '#000',
            textAlign: 'center',
            originX: 'center',
            originY: 'center',
          });
          this.canvas.add(this.textObjects[key]);
        });
      },
      createBottomArcTexts() {
        this.textObjects.bottomArc1 = new fabric.Text(this.editableTexts.bottomArc1, {
          left: this.canvas.width / 2,
          top: 375,
          fontSize: 13,
          fill: '#000',
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
        });
        this.canvas.add(this.textObjects.bottomArc1);

        // this.textObjects.bottomArc2 = new fabric.Text(this.editableTexts.bottomArc2, {
        //   left: this.canvas.width / 2,
        //   top: 420,
        //   fontSize: 18,
        //   fill: '#000',
        //   textAlign: 'center',
        //   originX: 'center',
        //   originY: 'center',
        // });
        // this.canvas.add(this.textObjects.bottomArc2);
      },
      createBottomText() {
        this.textObjects.bottom = new fabric.Text(this.editableTexts.bottom, {
          left: this.canvas.width / 2,
          top: this.canvas.height - 22,
          fontSize: 20,
          fill: '#000',
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
        });
        this.canvas.add(this.textObjects.bottom);
      },
      updateText(key) {
        if (this.textObjects[key]) {
          this.textObjects[key].set('text', this.editableTexts[key]);
          if (key === 'topArc') {
            this.textObjects[key].set('pathStartOffset', 0.5);
          }
          this.canvas.renderAll();
        }
      },
      exportCanvas() {
        const dataURL = this.canvas.toDataURL({
          format: 'png',
          quality: 1,
        });
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'edited_manhole_cover.png';
        link.click();
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    justify-content: space-between;
    margin: 0 auto;
  }
  
  .image-container {
    flex: 1;
    margin-right: 20px;
  }
  
  .edit-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 60%;
    padding: 5px;
  }
  
  button {
    margin-top: 20px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  canvas {
    height: auto;
    border: 1px solid #000;
  }
  </style>