// src/router.js
import Vue from 'vue';
import Router from 'vue-router';
import Design1 from './components/Design1.vue';
import Design2 from './components/Design2.vue';
import Design3 from './components/Design3.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/design1'
    },
    {
      path: '/design1',
      name: 'Design1',
      component: Design1
    },
    {
      path: '/design2',
      name: 'Design2',
      component: Design2
    },
    {
      path: '/design3',
      name: 'Design3',
      component: Design3
    }
  ]
});